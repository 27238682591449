import React from "react";
import Header from "./Header";
import Featurettes from "./Featurettes";
import Footer from "./Footer";
import DigitalMan from "./Assets/Stage.png";
import BwomanBlackHole from "./Assets/MilkyWayBook.png";
import BmanWorld2 from "./Assets/Meerkat.png";


const Learning: React.FC = () => {
  return (
    <>
      <Header />

    <div className="wrapper">
      <div className="typing-learning">
      <h1 className="mx-3 text-danger">learning. evolved.</h1>
      </div>
    </div>
    
      <Featurettes
        firstImage={DigitalMan}
        textOne="branded. solutions. branded learning products from bestselling authors and thought leaders offer several advantages for organizations looking to provide high-quality, effective learning experiences to their employees. Experts offer access to the latest research, insights, and best practices. Brand recognition can boost credibility, increase engagement, and motivate learners, while also building the organization's reputation as a leading provider of learning and development. Products are scalable by design, making it easy for organizations to adapt them to their specific needs and requirements. Branded learning products deliver tangible business results"
        secondImage={BwomanBlackHole}
        textTwo="customized. learning. our team of experienced eLearning authors will work with you to create customized learning experiences that are tailored to your specific needs and goals. From instructional design to content development, multimedia integration, and assessment creation, we'll take care of every aspect of your eLearning project to ensure a seamless and engaging experience for your learners. Our interactive and engaging content ensures that your learners stay motivated and on-track, while our assessments and analytics allow you to track progress and measure success"
        thirdImage={BmanWorld2}
        textThree="production. services. we understand the power of story in today's digital world. That's why we offer a wide range of services to help you create high-quality content that resonates with your target audience. From concept development to scriptwriting, filming, editing, and post-production, we'll take care of every aspect of your project to ensure a seamless and stress-free experience. We use the latest technologies and techniques to create content that stands out and makes an impact, including animation, motion graphics, 3D modeling, and more"
      />
      <Footer />
    </>
  );
};

export default Learning;
