import React from "react";
import SLogo from './Assets/LogoFinal.png';
import SVEnameLEmid from './Assets/SVEnameLEmid.png'


const Header: React.FC = () => {
  return (
    <div>
      <div className="mb-auto" data-bs-theme="dark">
        <nav
          className="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
          aria-label="Fourth navbar example"
        >
          <div className="container-fluid">
            <a className="navbar-brand" href="./">
              <img 
                src={SLogo} 
                alt="vector-e"
                width="60"
                height="60" 
              />
              <img 
                src={SVEnameLEmid} 
                alt="vector-e" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample04"
              aria-controls="navbarsExample04"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarsExample04">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="./">
                    home
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="./"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    learning. evolved.
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="/learning" >
                        learning
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/consulting">
                        consulting
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/technology">
                        technology
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    about
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <hr />
      <hr />
    </div>
  );
};

export default Header;
