import React from "react";
import Header from "./Header";
import Featurettes from "./Featurettes";
import Footer from "./Footer";
import DigitalMan from "./Assets/WizHat.png";
import BwomanBlackHole from "./Assets/SuperComputer.png";
import BmanWorld2 from "./Assets/BwomanLoop.png";

const Technology: React.FC = () => {
  return (
    <>
      <Header />

    <div className="wrapper">
      <div className="typing-technology">
      <h1 className="mx-3 text-danger">technology. infused.</h1>
      </div>
    </div>
    
      <Featurettes
        firstImage={DigitalMan}
        textOne="authoring. solutions. our team of authors understand both the nuances of story-driven learning and the technology backing today's emerging solutions. eLearning allows learners access on their schedules. Gamification incorporates game elements to increase learner engagement and motivation. Virtual and augmented reality (VR/AR) can be used to create immersive and interactive learning experiences, while machine learning and artificial intelligence (AI) can be used to personalize learning content and provide real-time feedback to learners. We author across modalities to meet your needs"
        secondImage={BwomanBlackHole}
        textTwo="technology. platforms. Learning Management Systems (LMSs) and Learning Experience Platforms (LXPs) provide learners with a personalized, engaging, and intuitive learning experience. Social learning tools enable learners to connect with peers, mentors, and subject matter experts, facilitating knowledge sharing and collaboration. AI-powered recommendations use machine learning algorithms to suggest personalized learning paths and content based on learners' preferences, interests, and performance data. LXPs also provide analytics dashboards to track learner progress, engagement, and completion rates, enabling learning and development professionals to assess the effectiveness of their programs and make data-driven decisions. By leveraging these tools and features, learners can engage with learning content in a way that suits their individual needs and preferences, leading to more effective and impactful learning outcomes"
        thirdImage={BmanWorld2}
        textThree="learning. analytics. learning analytics include surveys, assessments, and quizzes that provide quantitative and qualitative data on learner performance and satisfaction. Social network analysis, sentiment analysis, and natural language processing can also be used to gather insights on learner interactions, feedback, and sentiment. By leveraging these tools and strategies, learning and development professionals can gain valuable insights into the effectiveness of their programs, identify areas for improvement, and make data-driven decisions to drive business results"
      />
      <Footer />
    </>
  );
};

export default Technology;
