import React from "react";
import DigitalMan from "./Assets/DigitalMan.png";
import BwomanBlackHole from "./Assets/BwomanBlackHole.png";
import BmanWorld2 from "./Assets/BmanWorld.png";
import Background1 from "./Assets/Lightning.png";
import Background2 from "./Assets/Sun.png";
import Background3 from "./Assets/MilkyWayMountains.png";
import YinYang1 from "./Assets/Abstract4_1.png";
import YinYang2 from "./Assets/Abstract1.png";
import YinYang3 from "./Assets/Abstract5_1.png";
import Header from "./Header";
import Featurettes from "./Featurettes";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/carousel.css";
import "./css/carousel.rtl.css";
import "./css/cover.css";
import "./css/navbars.css";
import "bootstrap/dist/js/bootstrap.esm";



const Carousel: React.FC = () => {
  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={Background1}
            alt=""
            className="bd-placeholder-img"
            width="100%"
            height="100%"
            aria-hidden="true"
          />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>the learning revolution</h1>
              <p>
                yesterday's solutions solved yesterday's problems. Emerging technologies will reinvent the learning landscape. Are you ready?
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
        <img
            src={Background2}
            alt=""
            className="bd-placeholder-img"
            width="100%"
            height="100%"
            aria-hidden="true"
          />
          <div className="container">
            <div className="carousel-caption">
              <h1>power of story meets digital modality</h1>
              <p>
                by incorporating storytelling techniques into digital learning,
                we create a more immersive and memorable experience.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
        <img
            src={Background3}
            alt=""
            className="bd-placeholder-img"
            width="100%"
            height="100%"
            aria-hidden="true"
          />
          <div className="container">
            <div className="carousel-caption text-end">
              <h1>built to scale</h1>
              <p>get the expertise you need when you need it. vector-e assembles teams and resources dynamically.</p>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

const ContainerMarketing: React.FC = () => {
  return (
    <div className="container marketing">
      <div className="row">
        <div className="col-lg-4">
        <a className="btn btn-light" href="/learning">
        <img
            src={YinYang1}
            className="rounded-circle"
            width="140"
            height="140"
            aria-label="learning evolved"
          />
          </a>  
          <h4 className="text-danger">learning. evolved.</h4>
          <p className="text-secondary">branded solutions, real impact</p>
          <p>
            <a className="btn btn-dark" href="/learning">
              evolve &raquo;
            </a>
          </p>
        </div>
        <div className="col-lg-4">
        <a className="btn btn-light" href="/consulting">
        <img
            src={YinYang3}
            className="rounded-circle"
            width="140"
            height="140"
            aria-label="consulting transformed"
          />
          </a>
          <h4 className="text-danger">consulting. transformed.</h4>
          <p className="text-secondary">modern strategy for adaptive business</p>
          <p>
            <a className="btn btn-dark" href="/consulting">
              transform &raquo;
            </a>
          </p>
        </div>
        <div className="col-lg-4">
        <a className="btn btn-light" href="/technology">
        <img
            src={YinYang2}
            className="rounded-circle"
            width="140"
            height="140"
            aria-label="technology infused"
          />
          </a>
          <h4 className="text-danger">technology. infused.</h4>
          <p className="text-secondary">BYOA (Bring Your Own Acronym)</p>
          <p>
            <a className="btn btn-dark" href="/technology">
              infuse &raquo;
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div>
      <Header />

      <main>
        <Carousel />
        <ContainerMarketing />

        <Featurettes
          firstImage={DigitalMan}
          textOne="level up. to a new world of digital learning. we offer solutions for authors and thought leaders looking to expand their brand into digital learning. We offer expertise, development capability, and support to L&D professionals looking to level up their global, regional, and adaptive learning. Whatever the need, we can help"
          secondImage={BwomanBlackHole}
          textTwo="branded learning. from bestselling authors and thought leaders. 'do more with less' is the battle cry of modern business. With so much to do, who has time to sift through millions of courses, let alone sit through them? Branded learning solutions from today's top thought leaders filter the noise so you don't have to"
          thirdImage={BmanWorld2}
          textThree="think global. act local. a robust learning strategy demands enterprise-level thinking, regional localization, and individual adaptation. At vector-e, we guide authors, thought-leaders, and L&D professionals through the complexities of modern learning, from business logic, to emerging technologies, to regional localization, to creative development and beyond"
        />

      <Footer />
      </main>
    </div>
  );
}

export default App;
