import React from "react";
import Header from "./Header";
import Featurettes from "./Featurettes";
import Footer from "./Footer";
import DigitalMan from "./Assets/ElephantRoom.png";
import BwomanBlackHole from "./Assets/HBiz.png";
import BmanWorld2 from "./Assets/BmanCats.png";

const Consulting: React.FC = () => {
  return (
    <>
      <Header />

    <div className="wrapper">
      <div className="typing-consulting">
      <h1 className="mx-3 text-danger">consulting. transformed.</h1>
      </div>
    </div>
      <Featurettes
        firstImage={DigitalMan}
        textOne="learning. strategy. a learning strategy consultant can help transform a business by developing a comprehensive and tailored learning strategy that aligns with the organization's goals and objectives. The consultant will work closely with business leaders and stakeholders to understand the company's culture, values, and business needs, and assess the current state of the learning and development program. Based on this analysis, the consultant will develop a learning strategy that integrates best practices and innovative solutions to address any gaps and drive business results"
        secondImage={BwomanBlackHole}
        textTwo="branded. learning. branded learning solutions from best-selling authors and thought leaders can drive business results by providing employees with high-quality and engaging learning content. These solutions are designed to deliver insights and best practices from recognized experts in a particular field, providing learners with a valuable and credible source of information. Branded learning solutions may take the form of eLearning courses, videos, podcasts, or virtual events, and can cover a range of topics from leadership and management to technical skills and industry trends"
        thirdImage={BmanWorld2}
        textThree="learning delivery. and management. partnering with our learning consultants can provide a cost-effective and flexible solution, allowing companies to scale up or down as needed and avoid the costs and risks associated with building an in-house learning and development team. Additionally, consultants can provide facilitation and train-the-trainer services, as well as ongoing support and guidance, ensuring that the learning program continues to meet the company's evolving needs and business goals"
      />
      <Footer />
    </>
  );
};

export default Consulting;
