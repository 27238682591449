import React from "react";
import Logo from './Assets/LogoFinalVE.png';



const Footer: React.FC = () => {
    return (
      <footer className="container mx-auto d-block">
        <div className="b-example-divider">
        <img
              src={Logo}
              className="mx-auto d-block"

                            aria-label="Placeholder"
            />
            <div className="mx-auto">
                &copy; 2023 vector-e
                <p className="float-end">
                    <a href="./">home</a>
                </p>
            </div>
        </div>
      </footer>
    );
  };

export default Footer;