import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.esm";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/parallax-cards.css";


interface FeaturetteProps {
  firstImage: string;
  secondImage: string;
  thirdImage: string;
  textOne: string;
  textTwo: string;
  textThree: string;
}

const Featurettes: React.FC<FeaturetteProps> = (props) => {
  const [textOneRed, setTextOneRed] = useState("");
  const [textOneWhite, setTextOneWhite] = useState("");
  const [textOne, setTextOne] = useState("");

  const [textTwoRed, setTextTwoRed] = useState("");
  const [textTwoWhite, setTextTwoWhite] = useState("");
  const [textTwo, setTextTwo] = useState("");

  const [textThreeRed, setTextThreeRed] = useState("");
  const [textThreeWhite, setTextThreeWhite] = useState("");
  const [textThree, setTextThree] = useState("");

  const splitText = (text: string): string[] =>  {
    let split: string[] = text.split(".");
    split[0] = split[0] + "."
    split[1] = split[1] + "."
    const mainTextArr: string[] = split.slice(2)
    const mainText: string = mainTextArr.join(".").trimStart() + "."
    return([split[0], split[1], mainText])
  };

  useEffect(() => {
    const textOnes: string[] = splitText(props.textOne)
    setTextOneRed(textOnes[0])
    setTextOneWhite(textOnes[1])
    setTextOne(textOnes[2])

    const textTwos: string[] = splitText(props.textTwo)
    setTextTwoRed(textTwos[0]);
    setTextTwoWhite(textTwos[1]);
    setTextTwo(textTwos[2]);

    const textThrees: string[] = splitText(props.textThree)
    setTextThreeRed(textThrees[0]);
    setTextThreeWhite(textThrees[1]);
    setTextThree(textThrees[2]);
  }, [
    props.textOne,
    props.textTwo,
    props.textThree
  ]);

  return (
    <>
      <hr className="featurette-divider" />

      <div className="row featurette mx-3">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1; text-danger">
            {textOneRed}
            <span className="text-body-secondary-light; text-secondary">
              {textOneWhite}
            </span>
          </h2>
          <p className="lead">
              {textOne}
          </p>
        </div>
        <div className="col-md-5">
          <img
            src={props.firstImage}
            alt="circling the globe"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded parallax-card"
            width="500"
            height="500"
            aria-label="Placeholder: 500x500"
          />
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette mx-3">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1; text-danger">
           {textTwoRed}
            <span className="text-body-secondary; text-secondary">
                {textTwoWhite}
            </span>
          </h2>
          <p className="lead">
            {textTwo}
          </p>
        </div>
        <div className="col-md-5 order-md-1">
          <img
            src={props.secondImage}
            alt="circling the globe"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded parallax-card"
            width="500"
            height="500"
            aria-label="Placeholder: 500x500"
          />
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette mx-3">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1; text-danger">
            {textThreeRed}
            <span className="text-body-secondary; text-secondary">
                {textThreeWhite}</span>
          </h2>
          <p className="lead">
             {textThree}
          </p>
        </div>
        <div className="col-md-5">
          <img
            src={props.thirdImage}
            alt="circling the globe"
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto rounded parallax-card"
            width="500"
            height="500"
            aria-label="Placeholder: 500x500"
          />
        </div>
      </div>

      <hr className="featurette-divider" />
    </>
  );
};

export default Featurettes;
