import React from "react";
import Header from "./Header";
import Featurettes from "./Featurettes";
import Footer from "./Footer";
import DigitalMan from "./Assets/NewWorlds.png";
import BwomanBlackHole from "./Assets/Edgewalker.png";
import BmanWorld2 from "./Assets/ABiz.png";
import "./css/typing.css";

const About: React.FC = () => {
  return (
    <>
      <Header />

    <div className="wrapper">
      <div className="typing-about">
      <h1 className="mx-3 text-danger">about. vector-e.</h1>
      </div>
    </div>
      
    
      <Featurettes
        firstImage={DigitalMan}
        textOne="vector. eLearning. we are a learning and development company for the modern world. Specializing in design and development of branded learning solutions in the digital domain, we breath life into aged strategies and products"
        secondImage={BwomanBlackHole}
        textTwo="vector-e. authority. we are not only L&D developers and strategists, we are published authors and novelists, business experts, and leaders in our field"
        thirdImage={BmanWorld2}
        textThree="vector-e. leadership. we partner with authors, L&D professionals, and businesses to create modern learning with impact"
      />
      <Footer />
    </>
  );
};

export default About;
