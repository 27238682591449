import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Home';
import Learning from './Learning';
import Consulting from './Consulting';
import Technology from './Technology';
import About from './About';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
              <Route element={<App />} path='/' />
              <Route element={<Learning />} path='/learning' />
              <Route element={<Consulting />} path='/consulting' />
              <Route element={<Technology />} path='/technology' />
              <Route element={<About />} path='/about' />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
